.App {
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}

.App-logo {
  transform-origin: left bottom;
  animation: App-logo-shear forwards 0.3s cubic-bezier(0, 0.51, 0.58, 1);
  animation-delay: 0.25s;
  opacity: 0;
  width: 84vmin;
  height: 28vmin;
  pointer-events: none;
  user-select: none;
}

@keyframes App-logo-shear {
  from {
    opacity: 0;
    transform: translateY(0) skew(0, 0);
  }
  40% {
    opacity: 0.6;
    transform: translateY(-0.8rem) skew(0, -0.5deg);
  }
  to {
    opacity: 1;
    transform: translateY(-2rem) skew(0, -5deg);
  }
}

.App-header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: white;
}

.App-main {
  flex: auto;
}

.App-footer {
  font-size: calc(0.3rem + 1.2vmin);
  color: white;
  padding: 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-imprint {
  font-size: calc(0.3rem + 1.2vmin);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(0.5rem);
  background-color: rgba(0, 0, 0, 0.35);
  overflow: auto;
}

.App-imprint-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  z-index: 999;
  text-transform: uppercase;
  letter-spacing: 0.09rem;
  display: block;
  padding: 0.35rem 0.7rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2rem);
  user-select: none;
}

.App-imprint-close:hover {
  background: rgba(255, 255, 255, 0.2);
}
